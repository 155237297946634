<template>
    <div>
        <v-row v-if="isLoading" justify="center" no-gutters class="my-12">
            <v-progress-circular indeterminate color="primary" size="64" />
        </v-row>

        <v-row justify="center" align="center" v-if="!isLoading">
            <v-col sm="4" style="text-align: center;">
                <v-icon v-on:click="$router.back()">
                    mdi-arrow-left
                </v-icon>
            </v-col>

            <v-col sm="4" style="text-align: center;">
                <v-col>
                    <v-btn v-on:click="print()" depressed>
                        <v-icon>
                            mdi-printer
                        </v-icon>
                        Print Report
                    </v-btn>
                </v-col>
            </v-col>
            <v-col sm="4" style="text-align: center;">
                <v-col>
                    <v-dialog transition="dialog-top-transition" max-width="600" v-if="loan.situacao != 'PAID'">
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on">
                            <v-icon>
                                mdi-calculator
                            </v-icon>
                            Calculate pay-off
                        </v-btn>
                        </template>
                        <template v-slot:default="dialog">
                            <v-card>
                                <v-toolbar color="primary" dark>Select date of recalculation</v-toolbar>
                                <v-card-text style="text-align: center; margin-top: 15px;">
                                    <span>
                                        Please pay attention! This action will calculate the amortization amounts for the day picked. Click "Calculate" to confirm.
                                    </span>
                                    <div style="display: flex; justify-content: center; align-items: center; margin-top: 25px;">
                                        <date-picker v-model="dateCalculate.date" valueType="format"></date-picker>
                                    </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                    <v-btn text @click="dialog.value = false">
                                        Close
                                    </v-btn>
                                    <v-btn v-on:click="payoff(loan, dateCalculate)" depressed color="primary">
                                        Calculate
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </template>
                    </v-dialog>
                    <v-btn depressed color="success" v-if="loan.situacao == 'PAID' && !loan.paidWithPayOff">
                        All paid
                    </v-btn>
                    <v-btn depressed color="primary" v-if="loan.situacao == 'PAID' && loan.paidWithPayOff">
                        Paid with pay-off
                    </v-btn>
                </v-col>
            </v-col>
        </v-row>

        <v-container class="wrapper-report" v-if="!isLoading" id="printer-wrapper" style="margin-top: 30px;">
            <v-row justify="center" class="header-options" style="justify-content: center; align-items: center;">
                <v-col style="text-align: center;">
                    <h1 style="font-size: 2.7rem;">#{{loan.stock}}</h1> 
                </v-col>

                <v-col sm="12" style="text-align: center;">
                    <span style="font-size: 2rem;">{{loan.lastName}}</span>
                </v-col>

                <v-col style="text-align: center;">
                    <v-row align="center">
                        <v-col sm="2">
                            <h2>Partner: 
                                <span style="font-size: 20px;">
                                    <a v-on:click="loadPartner(loan.banco.id)">{{loan.banco.nome}}</a>
                                </span>
                            </h2>
                        </v-col>
                        <v-col sm="4">
                            <h3>Product: 
                                <span style="font-size: 15px;">
                                    {{loan.nomeCarro}}
                                </span>
                            </h3>
                        </v-col>
                        <v-col sm="3">
                            <h3>Start date: 
                                <span style="font-size: 16px;">
                                    {{loan.dataInicioContrato}}
                                </span>
                            </h3>
                        </v-col>
                        <v-col sm="3">
                            <h3>Loan amount: 
                                <span style="font-size: 16px;">
                                    {{loan.totalPagar | toCurrency}}
                                </span>
                            </h3>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row justify="center" class="table" style="margin-top: 20px;">
                <v-simple-table
                    dense
                    v-if="!isLoading"
                    style="width: 100%;"
                >
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th scope="col" class="text-center">Payment No.</th>
                        <th scope="col" class="text-center">Days</th>
                        <th scope="col" class="text-center" style="min-width: 120px;">Due date</th>
                        <th scope="col" class="text-center">Interest</th>
                        <th scope="col" class="text-center">Principal</th>
                        <th scope="col" class="text-center">Payment</th>
                        <th scope="col" class="text-center">Principal payment</th>
                        <th scope="col" class="text-center">Principal balance</th>
                        <th scope="col" class="text-center">Status</th>
                        <th scope="col" class="text-center" style="min-width: 120px;">Paid date</th>
                        <th scope="col" class="text-center hide-for-print">
                            Pay 
                            <input class="input-pay" type="checkbox" v-on:click="checkAll()" style="margin-left: 10px;" v-model="isCheckAll">
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="parcela in loan.parcelas"
                        :key="parcela.id"
                        >
                        <td class="text-center">{{ parcela.nroParcela }}</td>
                        <td class="text-center">{{ defineDays(parcela) }}</td>
                        <td class="text-center">{{ parcela.dataPagamento }}</td>
                        <td class="text-center">{{ parcela.vlParcelaJuros | toCurrency}}</td>
                        <td class="text-center">{{parcela.vlParcela + parcela.balanceAfterPayment | toCurrency}}</td>
                        <td class="text-center">{{ parcela.vlParcela | toCurrency }}</td>
                        <td class="text-center">{{ parcela.vlParcela - parcela.vlParcelaJuros | toCurrency}}</td>
                        <td class="text-center">{{ parcela.balanceAfterPayment | toCurrency}}</td>
                        <td class="text-center"><v-btn x-small :color="defineColor(parcela, loan)">{{ loan.paidWithPayOff ? 'Pay-off' : parcela.situacao}}</v-btn></td>
                        <td class="text-center">
                            {{parcela.dataPagamentoPaga}}                            
                        </td>
                        <td class="text-center hide-for-print" style="min-width: 120px;">
                            <span class="pay-wrapper" v-if="parcela.situacao != 'PAID' && parcela.situacao != 'DEACTIVADED' && parcela.lastLoanPaid">
                                <span style="margin-right: 10px;">
                                    <input class="input-pay" v-bind:value='parcela' type="checkbox" v-model="loansList" @change="updateCheckAll(parcela)">
                                    <span>
                                        Pay
                                    </span>
                                </span>
                                <span style="margin-top: 4px;" v-if="showDatePicker">
                                    <date-picker v-model="payDate.date" valueType="format" style="width: 115px;"></date-picker>
                                </span>
                            </span>
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-row>
            <v-row style="text-align: center; margin-top: 30px;">
                <v-col>
                    <strong>Total Loans: </strong> {{loan.parcelas.length}}
                </v-col>

                <v-col>
                    <strong>Total Interest: </strong> {{totalInterests | toCurrency}}
                </v-col>

                <v-col>
                    <strong>Principal Payment: </strong> {{totalPayments | toCurrency}}
                </v-col>

                <v-col v-if="loansList.length" class="hide-for-print">
                    <v-text-field
                        label="Observation"
                        v-model="comentario"
                    ></v-text-field>
                    <v-btn depressed color="primary" v-on:click="bulkPay(loansList, payDate, comentario)">
                        Pay Loan
                    </v-btn>
                </v-col> 
            </v-row>
        </v-container>
    </div>
</template>

<script>;
import CreateToast from '../../../utils/createToast';
import LoanService from '../services/LoanService';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

    export default {
        name: "LoanReport",
        components: {
            DatePicker
        },
        data() {
            return {
                isLoading: false,
                loan: {},
                totalInterests: 0.0,
                totalPayments: 0.0,
                showDatePicker: false,
                comentario: "",
                loansList: [],
                itensSelected: [],
                payDate: { 
                    date: moment().format('YYYY-MM-DD')
                },
                dateCalculate: {
                    date: moment().format('YYYY-MM-DD')
                },
                checkKey: 0,
                isCheckAll: false,
            }
        },
        async created() {
            this.init();
        },

        methods: {
            async init() {
                this.isLoading = true;
                LoanService.findByIdReport(this.$route.params.id).then(response => {
                    this.loan = response.data;
                    this.totalPayments = _.sumBy(this.loan.parcelas, 'vlParcela');
                    this.totalInterests = _.sumBy(this.loan.parcelas, 'vlParcelaJuros')
                }).catch(err => {
                    CreateToast.createToastFailed('An error ocurred! Please try again!');
                }).finally(() => {
                    this.isLoading = false;
                });
            },

            print() {
                $('.hide-for-print').hide();
                this.$router.push({ path: `/loans/${this.$route.params.id}` });
                return this.$htmlToPaper('printer-wrapper');
            },

            loadPartner(idPartner) {
                this.$router.push(`/partners/${idPartner}`);
            },

            checkAll() {
                this.isCheckAll = !this.isCheckAll;
                this.isCheckAll ? this.showDatePicker = true : this.showDatePicker = false;
                this.loansList = [];
                var filter = _.filter(this.loan.parcelas, (parcela) => { return parcela.situacao == 'UNPAID' && parcela.lastLoanPaid })
                if(this.isCheckAll){ // Check all
                    for (var key in filter) {
                        console.log(key)
                        this.loansList.push(filter[key]);
                    }
                }
            },

            updateCheckAll(parcela) {
                this.payDate.date = parcela.dataPagamento;
                var filter = _.filter(this.loan.parcelas, (parcela) => { return parcela.situacao == 'UNPAID' && parcela.lastLoanPaid })
                if(this.loansList.length == filter.length){
                    this.isCheckAll = true;
                    this.showDatePicker = true
                }else{
                    this.isCheckAll = false;
                    this.showDatePicker = false
                }
            },

            bulkPay(loansList, payDate, comentario) {
                this.showDatePicker = false;
                if (this.checkMoreThanOnePartner(loansList)) {
                    this.isLoading = true;
                    CreateToast.createToastInfo('Payment in process!', 'Sending emails to partners to verify your paid loans.');

                    let nrosParcelas = [];

                    loansList.map(loan => {
                        nrosParcelas.push(loan.nroParcela);
                    })
                    
                    LoanService.pay(loansList[0].idContrato, nrosParcelas, payDate.date, false, comentario).then(response => {
                        CreateToast.createToastSuccess('Payments processed with success.');
                    }).then(() => {
                        this.$router.replace({ path: `/loans/${this.$route.params.id}` });
                    }).catch(err => {
                        CreateToast.createToastFailed('An error ocurred! Please try again!');
                    }).finally(() => {
                        this.isLoading = false;
                    });

                    this.$forceUpdate(this.loans);
                    this.$forceUpdate(this.partners);
                }
            },

            checkMoreThanOnePartner(loanList) {
                let newList = _.uniqBy(loanList, (loan) => {
                    return loan.idContrato;
                });

                if (newList.length > 1) {
                    CreateToast.createToastWarning(`It's not possible pay two different partners at the same time. Please, use filters to find and pay separately!`);
                    return false;
                }

                return true;
            },

            defineDays(parcela) {
                if (parcela.diasPrimeiraParcela) {
                    return parcela.diasPrimeiraParcela;
                }

                if (parcela.daysFromLastPayment != null) {
                    return parcela.daysFromLastPayment;
                }

                return parcela.diasProximaParcela;
            },

            hasAnyPaidLoan() {
                var loansPaid = _.filter(this.loan.parcelas, (loan) => { return loan.dataPagamentoPaga != null }).length;
                if (loansPaid > 1) {
                    return true;
                } 
                
                return false;
            },

            payoff(contrato, data) {
                this.$router.push({ path: `/calculatePayoff/contract/${contrato.id}/${data.date}` });
            },

            defineColor(parcela, contrato) {
                if (parcela.situacao == 'UNPAID') {
                    return 'default';
                }

                if (parcela.situacao == 'DEACTIVADED') {
                    return 'error'
                }

                if (parcela.situacao == 'PAID' && !contrato.paidWithPayOff) {
                    return 'success'
                }

                return 'primary'
            }
        }
    }
</script>

<style scoped>
    a {
        text-decoration: none;
    }

    .pay-wrapper {
        display: flex;
        flex-direction: row;
    }
</style>